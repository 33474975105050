<template>
  <top></top>
  <div class="container">
    <div class="row contentTop">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 leftBd">
        <ul>
          <dt @click="about">关于我们</dt>
          <dt @click="mechanismSj">机构介绍</dt>
          <dt @click="leader">领导介绍</dt>
          <dt @click="mechanismSetUp">机构设置</dt>
          <dd></dd>
          <li><img :src="erwei" class="img-responsive center-block" /></li>
          <li>电话:010-57108081</li>
        </ul>
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-9 minTop">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newTop">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>测评申报</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newLst">
            <dt>测评申报</dt>
             <el-form ref="data" :rules="rules" :model="data"  label-position="left">
              <el-form-item prop="region">
                <el-input v-model="data.region" placeholder="申报地区" ></el-input>
              </el-form-item>

              <el-form-item prop="declare_majo">
                <el-input v-model="data.declare_majo" placeholder="申报专业"></el-input>
              </el-form-item>

              <el-form-item prop="declare_unit">
                <el-input v-model="data.declare_unit"  placeholder="申报单位/培训学校"></el-input>
              </el-form-item>

              <el-form-item prop="peoples">
                <el-input v-model="data.peoples" type="number" placeholder="预计每年测评人数"></el-input>
              </el-form-item>

              <el-form-item prop="phone">
                <el-input v-model="data.phone" placeholder="联系方式"></el-input>
              </el-form-item>

              <el-form-item prop="address">
                <el-input v-model="data.address" placeholder="地址"></el-input>
              </el-form-item>

              <el-form-item prop="email">
                <el-input v-model="data.email" placeholder="邮箱"></el-input>
              </el-form-item>

              <el-button plain type="primary" @click="submitData('data')">提交</el-button>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <bottom></bottom>
</template>

<script>
import { post } from "@/http/api";
import Top from "./top.vue";
import bottom from "./bottom.vue";
import erwei from "@/assets/erwei.png";

export default {
  name: "declare",
  components: {
    Top,
    bottom,
    erwei,
  },
  data() {
    return {
      erwei,
      data: {
        region: "",
        declare_majo: "",
        declare_unit: "",
        peoples: "",
        phone: "",
        address: "",
        email: "",
      },
      labelPosition: "left",
      rules: {
          region: [{required: true, message: '请输入申报地区', trigger: 'blur'}],
          declare_majo: [{ required: true, message: '请输入申报专业', trigger: 'blur'}],
          declare_unit: [{ required: true, message: '请输入申报单位/培训学校', trigger: 'blur'}],
          peoples: [{required: true,message: '请输入预计每年测评人数',trigger: 'blur'}],
          phone: [{ required: true, message: '请输入联系方式',trigger: 'blur'}],
          address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
          email: [{ required: true, message: '请输入邮箱', trigger: 'blur' },{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}],
        }
    };
  },

  watch: {},

  created() {},

  methods: {
    submitData(data) {
      console.log(data);
         this.$refs[data].validate((valid) => {
          if (valid) {
             let data = this.data;
            const qs = require("qs");
            post("Index/adddeclare", qs.stringify(data)).then((res) => {
                if (res.code == 200) {
                  this.$message({
                      message: res.msg,
                      type: "success",
                  });
                  this.data = {};
                } else {
                  this.$message({
                      message: res.msg,
                      type: "warning",
                  });
                }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },

    //跳转关于我们
    about(){
      this.$router.push({ path: `/About` });
      this.$forceUpdate();
    },
     
    //跳转机构介绍
    mechanismSj(){ 
      this.$router.push({ path: `/Mechanism` });
      this.$forceUpdate();
    },

    //跳转领导介绍
    leader(){ 
      this.$router.push({ path: `/Leader` });
      this.$forceUpdate();
    },

    //跳转机构设置
    mechanismSetUp(){ 
      this.$router.push({ path: `/Mechanismsetup` });
      this.$forceUpdate();
    },
    
  },
};
</script> 
<style scoped>
@media (max-width: 768px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 3px;
    font-size: 1rem;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-weight: normal;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 0px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 0px;
    text-align: center;
    font-size: 1rem;
  }
  .leftBd ul li img {
    width: 80%;
  }
  :deep .el-breadcrumb {
    font-size: 1rem;
    line-height: 1;
  }
  /* 右侧 */
  .minTop {
    min-height: 365px;
  }
  .newTop {
    margin-bottom: 2%;
  }

  .newLst dt {
    font-size: 1.1rem;
  }
  .newLst {
    text-align: center;
    border-radius: 5px;
    border: solid 1px#0000001f;
    background: #00000000;
    padding-top:2%;
    padding-bottom: 3%;
  }

  .newLst >>> .el-input__inner {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .newLst >>> .el-button {
    width: 100%;
  }

  .newLst >>> .el-form-item__content {
    margin-left: 0px !important;
  }
}

@media (min-width: 768px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }

  .newLst {
    text-align: center;
    border-radius: 5px;
    border: solid 1px#0000001f;
    background: #00000000;
    padding-top:2%;
    padding-bottom: 3%;
  }

  .newLst dt {
    padding-bottom: 2%;
  }

  .newLst >>> .el-input__inner {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .newLst >>> .el-button {
    width: 100%;
  }

  .newLst >>> .el-form-item__content {
    margin-left: 0px !important;
  }
}

@media (min-width: 992px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }

  .newLst {
    text-align: center;
    border-radius: 5px;
    border: solid 1px#0000001f;
    background: #00000000;
    padding-top:2%;
    padding-bottom: 3%;
  }

  .newLst dt {
    padding-bottom: 2%;
  }

  .newLst >>> .el-input__inner {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .newLst >>> .el-button {
    width: 100%;
  }

  .newLst >>> .el-form-item__content {
    margin-left: 0px !important;
  }
}

@media (min-width: 1200px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }

  .newLst {
    text-align: center;
    border-radius: 5px;
    border: solid 1px#0000001f;
    background: #00000000;
    padding-top: 2%;
    padding-bottom: 3%;
  }

  .newLst dt {
    padding-bottom: 2%;
  }

  .newLst >>> .el-input__inner {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .newLst >>> .el-button {
    width: 100%;
  }

  .newLst >>> .el-form-item__content {
    margin-left: 0px !important;
  }
}
</style>